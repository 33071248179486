<script>
import appConfig from "@/app.config";
import CalendarApiService from "@/services/calendarService";
import userConfigMixin from "@/mixins/userConfigMixin";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "user",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  mixins: [userConfigMixin],
  data() {
    return {
      title: "Users",
      isLoading: false,
      selected: "",
      items: [
        {
          text: "users",
          href: "/users",
        },
        {
          text: "",
          active: true,
        },
      ],
      roles: ["admin", "user"],
      userConfigModel: {
        id: null,
        displayName: null,
        planificationRole: null,
        documentationBillsVisible: null,
        documentationContractsVisible: null,
        documentationOthersVisible: null,
        documentationPlansAndDrawingsVisible: null,
        documentationPurchaseOrdersVisible: null,
        documentationQuotesVisible: null,
        permissions: null,
        notifications: null,
        productionVisibility: null,
        progressVisibility: [],
        shippersVisibility: [],
        installersVisibility: [],
        calendarBackschedulingCalculation: null,
      },
      userConfigModelDiscardCopy: null,
    };
  },
  beforeCreate() {},
  created() {
    let userId = this.$route.params.userId;
    this.isLoading = true;
    CalendarApiService.getUserConfig(userId).then((response) => {
      this.userConfigModel = response.data.data;
      this.userConfigModel.planificationRole =
        response.data.data.planificationRole;
      this.userConfigModel.progressVisibility =
        this.userConfigModel.progressVisibility.sort(
          (a, b) => a.progress.order - b.progress.order
        );

      //sorted list avec unassigned on top
      let unassignedShipperConfig =
        this.userConfigModel.shippersVisibility.filter((sv) => {
          return sv.shipper == null;
        })[0];
      let assignedShippersConfig =
        this.userConfigModel.shippersVisibility.filter((sv) => {
          return sv.shipper != null;
        });
      this.userConfigModel.shippersVisibility = assignedShippersConfig.sort(
        (a, b) => (a.shipper?.name > b.shipper?.name ? 1 : -1)
      );
      this.userConfigModel.shippersVisibility.unshift(unassignedShipperConfig);

      //sorted list avec unassigned on top
      let unassignedInstallerConfig =
        this.userConfigModel.installersVisibility.filter((iv) => {
          return iv.installer == null;
        })[0];
      let assignedInstallersConfig =
        this.userConfigModel.installersVisibility.filter((iv) => {
          return iv.installer != null;
        });
      this.userConfigModel.installersVisibility = assignedInstallersConfig.sort(
        (a, b) => (a.installer?.name > b.installer?.name ? 1 : -1)
      );
      this.userConfigModel.installersVisibility.unshift(
        unassignedInstallerConfig
      );

      this.userConfigModelDiscardCopy = JSON.parse(
        JSON.stringify(this.userConfigModel)
      );

      this.isLoading = false;
    });
  },
  mounted() {},
  computed: {},
  methods: {
    saveUserConfigModel() {
      this.isLoading = true;

      var userConfigModelCopy = JSON.parse(
        JSON.stringify(this.userConfigModel)
      );
      CalendarApiService.saveUserConfig(userConfigModelCopy).then(() => {
        //si user edit sa propre config, reassigner la config user
        var currentUserId = this.userConfig.userId;
        if (currentUserId == this.userConfigModel.userId) {
          let userConfigModelTmp = JSON.parse(
            JSON.stringify(this.userConfigModel)
          );
          //Set in store
          this.$store.commit("calendar/setUserConfig", userConfigModelTmp);
        }

        this.isLoading = false;
        this.$router.push({ name: "users" });
      });
    },
    discard() {
      this.userConfigModel = JSON.parse(
        JSON.stringify(this.userConfigModelDiscardCopy)
      );
    },
    toogleChange(
      newValue,
      property,
      visibilityName,
      subVisibilityName,
      isEditor
    ) {
      if (Array.isArray(this.userConfigModel[visibilityName])) {
        this.userConfigModel[visibilityName].forEach((visibilityConfig) => {
          this.toogleProperty(
            newValue,
            property,
            visibilityConfig,
            subVisibilityName,
            isEditor
          );
        });
      } else if (this.userConfigModel[visibilityName] instanceof Object) {
        this.toogleProperty(
          newValue,
          property,
          this.userConfigModel[visibilityName],
          subVisibilityName,
          isEditor
        );
      }
    },
    toogleProperty(
      newValue,
      property,
      visibilityConfig,
      subVisibilityName,
      isEditor
    ) {
      visibilityConfig[property] = newValue;
      if (isEditor === true) {
        this.editPermissionChanged(visibilityConfig, subVisibilityName);
      } else if (subVisibilityName != undefined) {
        visibilityConfig[subVisibilityName].forEach((subVisibility) => {
          subVisibility[property] = newValue;
        });
      }
    },
    editPermissionChanged(visibility, subVisibilityName) {
      if (visibility.editor === true) {
        this.$set(visibility, "display", true);
        if (subVisibilityName !== undefined) {
          for (var subVisibility of visibility[subVisibilityName]) {
            this.$set(subVisibility, "display", true);
          }
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="$t('general.usersDetails')" /> -->
    <div
      style="
        flex: 1 1 1px;
        display: flex;
        flex-direction: column;
        margin: 0 -24px -60px -24px;
      "
    >
      <div
        class="p-2 pl-4 pr-4 pb-2"
        style="padding-right: 2.5rem !important; padding-bottom: 0 !important"
      >
        <div
          style="
            padding: 20px;
            background: white;
            border-bottom: 1px solid rgba(204, 204, 204, 0.8);
          "
        >
          <b-button
            @click="saveUserConfigModel"
            variant="success"
            class="mr-2"
            >{{ $t("general.save") }}</b-button
          >
          <b-button @click="discard" variant="light">{{
            $t("general.discard")
          }}</b-button>
        </div>
      </div>
      <div
        class="p-2 pl-4 pr-4"
        style="
          flex: 1 1 1px;
          min-height: 0;
          overflow-y: scroll;
          position: relative;
        "
      >
        <div class="card">
          <div class="card-body" style="position: relative">
            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              color="#f1b44c"
              :width="70"
              :height="70"
              loader="dots"
              :is-full-page="false"
            >
            </loading>
            <div v-if="userConfigModel.id != null">
              <h2 class="mb-4">{{ userConfigModel.displayName }}</h2>
              <div class="mb-4">
                <h5>{{ $t("general.planificationRole") }}</h5>
                <ul style="padding-left: 15px; list-style-type: square">
                  <li>
                    <small>{{ $t("general.adminRoleText") }}</small>
                  </li>
                  <li>
                    <small>{{ $t("general.userRoleText") }}</small>
                  </li>
                </ul>
                <multiselect
                  style="max-width: 250px"
                  v-model="userConfigModel.planificationRole"
                  :show-labels="false"
                  :options="roles"
                >
                </multiselect>
              </div>

              <div class="mb-4">
                <h5>{{ $t("general.permissions") }}</h5>
                <b-form-checkbox
                  v-model="userConfigModel.permissions.createWorkOrder"
                  class="custom-checkbox mb-3"
                  >{{
                    $t("general.createWorkOrderPermission")
                  }}</b-form-checkbox
                >
                <b-form-checkbox
                  v-model="userConfigModel.permissions.deleteWorkOrder"
                  class="custom-checkbox mb-3"
                >
                  {{ $t("general.deleteWorkOrder") }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="userConfigModel.permissions.accessWorkOrdersListPage"
                  class="custom-checkbox mb-3"
                  >{{ $t("general.accessWorkOrdersListPage") }}</b-form-checkbox
                >
                <b-form-checkbox
                  v-model="userConfigModel.permissions.accessCapacityDashboard"
                  class="custom-checkbox mb-3"
                  >{{ $t("general.accessCapacityDashboard") }}</b-form-checkbox
                >
              </div>

              <div class="mb-4">
                <h5>{{ $t("general.notifications") }}</h5>
                <b-form-checkbox
                  v-model="
                    userConfigModel.notifications.receiveWorkOrderNotifications
                  "
                  class="custom-checkbox"
                  >{{
                    $t("general.receiveWorkOrderNotifications")
                  }}</b-form-checkbox
                >
              </div>

              <div class="mb-4">
                <h5>{{ $t("general.calendarBackschedulingCalculation") }}</h5>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio
                    v-model="userConfigModel.calendarBackschedulingCalculation"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="referenceDate"
                    >{{ $t("general.workOrderReferenceDate") }}</b-form-radio
                  >
                  <b-form-radio
                    class="mt-2"
                    v-model="userConfigModel.calendarBackschedulingCalculation"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="currentDate"
                    >{{ $t("general.currentDate") }}</b-form-radio
                  >
                </b-form-group>
              </div>

              <div class="mb-4">
                <h5>{{ $t("general.documentationCategoriesVisibility") }}</h5>
                <div style="display: flex">
                  <b-form-checkbox
                    v-model="
                      userConfigModel.documentationPlansAndDrawingsVisible
                    "
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.plansAndDrawings") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.plansAndDrawings')"
                        class="ml-2 mdi mdi-text-box-multiple-outline"
                      ></i>
                    </span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="userConfigModel.documentationContractsVisible"
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.contracts") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.contracts')"
                        class="ml-2 mdi mdi-handshake"
                      ></i>
                    </span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="userConfigModel.documentationBillsVisible"
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.bills") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.bills')"
                        class="ml-2 mdi mdi-file-table-outline"
                      ></i>
                    </span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="userConfigModel.documentationPurchaseOrdersVisible"
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.purchaseOrder") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.purchaseOrder')"
                        class="ml-2 mdi mdi-cart-outline"
                      ></i>
                    </span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="userConfigModel.documentationQuotesVisible"
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.quotes") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.quotes')"
                        class="ml-2 mdi mdi-clipboard-list-outline"
                      ></i>
                    </span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="userConfigModel.documentationOthersVisible"
                    class="custom-checkbox mr-5"
                  >
                    <span style="display: flex; align-items: center">
                      {{ $t("general.otherDocuments") }}
                      <i
                        v-b-tooltip.hover
                        style="font-size: 1.7em"
                        :title="$t('general.otherDocuments')"
                        @click="openDocumentationFiles('others')"
                        class="ml-2 mdi mdi-dots-horizontal-circle-outline"
                      ></i>
                    </span>
                  </b-form-checkbox>
                </div>
              </div>

              <div class="mb-4" zzstyle="max-width:1000px;">
                <h5>{{ $t("general.visibilityCalendar") }}</h5>
                <ul style="padding-left: 15px; list-style-type: square">
                  <li>
                    <small>{{
                      $t("general.visibilityCalendarDisplayText")
                    }}</small>
                  </li>
                  <li>
                    <small>{{
                      $t("general.visibilityCalendarEditorText")
                    }}</small>
                  </li>
                  <li>
                    <small>{{
                      $t("general.visibilityCalendarDisplayCapacitiesText")
                    }}</small>
                  </li>
                </ul>
                <div class="table-responsive">
                  <table class="table mb-0 table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.progress") }}</th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'display',
                                  'progressVisibility',
                                  'progressUserVisibility'
                                )
                            "
                            >{{ $t("general.displayConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'editor',
                                  'progressVisibility',
                                  'progressUserVisibility',
                                  true
                                )
                            "
                            >{{ $t("general.editorConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'displayInWorkOrderInfoSection',
                                  'progressVisibility'
                                )
                            "
                            >{{
                              $t("general.displayInfoWorkOrder")
                            }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'displayInLegend',
                                  'progressVisibility'
                                )
                            "
                            >{{
                              $t("general.displayInLegend")
                            }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'notifyStarted',
                                  'progressVisibility'
                                )
                            "
                            >{{ $t("general.notifystarted") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'notifyCompleted',
                                  'progressVisibility'
                                )
                            "
                            >{{
                              $t("general.notifycompleted")
                            }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'displayCapacities',
                                  'progressVisibility'
                                )
                            "
                            >{{
                              $t("general.displayCapacitiesConfig")
                            }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'displayDamaged',
                                  'progressVisibility'
                                )
                            "
                            >{{ $t("general.displayDamaged") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'displayBackOrder',
                                  'progressVisibility'
                                )
                            "
                            >{{
                              $t("general.displayBackOrder")
                            }}</b-form-checkbox
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="progressVisibility in userConfigModel.progressVisibility"
                      >
                        <tr :key="progressVisibility.id">
                          <td
                            :class="{
                              'font-weight-bold':
                                progressVisibility.progressUserVisibility
                                  .length > 0,
                            }"
                          >
                            {{ progressVisibility.progress.name }}
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.display"
                              :disabled="progressVisibility.editor"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.editor"
                              @change="
                                editPermissionChanged(
                                  progressVisibility,
                                  'progressUserVisibility'
                                )
                              "
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="
                                progressVisibility.displayInWorkOrderInfoSection
                              "
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.displayInLegend"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.notifyStarted"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.notifyCompleted"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.displayCapacities"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.displayDamaged"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="progressVisibility.displayBackOrder"
                            ></b-form-checkbox>
                          </td>
                        </tr>
                        <tr
                          v-for="userVisibility in progressVisibility.progressUserVisibility"
                          :key="userVisibility.id"
                        >
                          <td style="font-size: 0.9em; font-style: italic">
                            <span class="ml-2 text-nowrap">{{
                              userVisibility.displayName === null
                                ? $t("general.unassigned")
                                : userVisibility.displayName
                            }}</span>
                          </td>
                          <td style="text-align: center">
                            <b-form-checkbox
                              size="lg"
                              switch
                              v-model="userVisibility.display"
                              :disabled="progressVisibility.editor"
                            ></b-form-checkbox>
                          </td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                          <td style="text-align: center"></td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive">
                  <table class="table mb-0 table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.shippers") }}</th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'display',
                                  'shippersVisibility'
                                )
                            "
                            >{{ $t("general.displayConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'editor',
                                  'shippersVisibility',
                                  undefined,
                                  true
                                )
                            "
                            >{{ $t("general.editorConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'notify',
                                  'shippersVisibility'
                                )
                            "
                            >{{ $t("general.notifyConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center"></th>
                        <th style="width: 250px; text-align: center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          shipperVisibility, index
                        ) in userConfigModel.shippersVisibility"
                        :key="index"
                      >
                        <td>
                          {{
                            shipperVisibility.shipper == null
                              ? $t("general.unassigned")
                              : shipperVisibility.shipper.name
                          }}
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="shipperVisibility.display"
                            :disabled="shipperVisibility.editor"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="shipperVisibility.editor"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="shipperVisibility.notify"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center"></td>
                        <td style="text-align: center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive">
                  <table class="table mb-0 table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.installers") }}</th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'display',
                                  'installersVisibility'
                                )
                            "
                            >{{ $t("general.displayConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'editor',
                                  'installersVisibility',
                                  undefined,
                                  true
                                )
                            "
                            >{{ $t("general.editorConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'notify',
                                  'installersVisibility'
                                )
                            "
                            >{{ $t("general.notifyConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center"></th>
                        <th style="width: 250px; text-align: center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          installerVisibility, index
                        ) in userConfigModel.installersVisibility"
                        :key="index"
                      >
                        <td>
                          {{
                            installerVisibility.installer == null
                              ? $t("general.unassigned")
                              : installerVisibility.installer.name
                          }}
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="installerVisibility.display"
                            :disabled="installerVisibility.editor"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="installerVisibility.editor"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="installerVisibility.notify"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center"></td>
                        <td style="text-align: center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive">
                  <table class="table mb-0 table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.production") }}</th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'display',
                                  'productionVisibility'
                                )
                            "
                            >{{ $t("general.displayConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'editor',
                                  'productionVisibility',
                                  undefined,
                                  true
                                )
                            "
                            >{{ $t("general.editorConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center">
                          <b-form-checkbox
                            size="md"
                            switch
                            @change="
                              (newValue) =>
                                toogleChange(
                                  newValue,
                                  'notify',
                                  'productionVisibility'
                                )
                            "
                            >{{ $t("general.notifyConfig") }}</b-form-checkbox
                          >
                        </th>
                        <th style="width: 250px; text-align: center"></th>
                        <th style="width: 250px; text-align: center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ $t("general.production") }}</td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="
                              userConfigModel.productionVisibility.display
                            "
                            :disabled="userConfigModel.productionVisibility.editor"
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="
                              userConfigModel.productionVisibility.editor
                            "
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center">
                          <b-form-checkbox
                            size="lg"
                            switch
                            v-model="
                              userConfigModel.productionVisibility.notify
                            "
                          ></b-form-checkbox>
                        </td>
                        <td style="text-align: center"></td>
                        <td style="text-align: center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
